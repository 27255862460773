.content {
  display: flex;
  gap: 2em;
  align-items: center;

  .result {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      border-radius: 6px;
      border: solid 1px #afbadd;
      cursor: pointer;

      :hover {
        transform: scale(105);
      }
    }

    .fileName {
      width: 300px;
      margin: 3px 0 4px 9px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #5349b7;
    }

    .fileSize {
      margin: 4px 76px 2px 9px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #5349b7;
    }
  }

  .preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(25, 45, 50, 0.18);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999999 !important;
    transition: 0.2s all;
    img {
      width: 70vw;
      height: 70vh;
      border-radius: 10px;
      cursor: pointer;
      object-fit: contain;
    }
  }
}
