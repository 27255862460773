.highlight {
  display: flex;
  padding: 10px 16px;
  margin: 12px 0px;
  gap: 1em;
  border-radius: 4px;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.13;

  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;

  &.enter {
    opacity: 1;
    transform: translateY(0);
  }

  &.exit {
    opacity: 0;
    transform: translateY(-20px);
  }

  .title {
    font-size: 16px !important;
    font-weight: 900 !important;
  }

  .subtitle {
    font-size: 12px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.error {
  background-color: var(--color-blush);
  .title {
    color: var(--color-salsa);
  }

  .subtitle {
    color: var(--color-salsa);
  }
}

.errorIcon {
  color: var(--color-salsa);
  margin-right: 10px;
}

.success {
  background-color: #dff8f2;
  color: #10ac84 !important;
  .title {
    color: #10ac84;
  }

  .subtitle {
    color: #10ac84;
  }
  .successIcon {
    color: #10ac84;
    margin-right: 10px;
  }
}
