.subtitle {
  font-size: 16px !important;
  margin-bottom: 18px;
  color: var(--color-night);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.fieldContent {
  width: 290px;
  img {
    margin-left: -1em;
  }
}

.productInput {
  display: grid;
  grid-template-columns: 1.3fr 0.7fr 1fr 1fr;
  align-items: center;
  gap: 2em;
  min-width: auto !important;
  justify-content: space-between;
}

.viewProductInput {
  display: grid;
  grid-template-columns: 0.8fr 0.3fr 1fr;
  align-items: center;
  gap: 3em;
  margin-bottom: 1.5em;
}

.close {
  background: none;
  border: 1px solid var(--color-gray);
  color: var(--color-purple-variant);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 3px;
  font-weight: 900;
  .icon {
    font-weight: 900;
    margin: auto;
    transition: font-size 0.1s;
    color: var(--color-night);
  }

  &:hover {
    .icon {
      font-size: 10.5px;
    }
  }
}

.button {
  color: var(--color-purple-variant);
  border: 1px solid var(--color-purple-variant);
  font-weight: bold;
  font-size: 14px;
}

.flexCol {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.labelContainer {
  background: var(--color-whitesmoke);
  height: 38px;
  margin-right: -13px;
  border-radius: 3px 0px 0px 3px;
  border-right: 1px solid var(--color-ash);
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-night);
}

.selectStationsActions {
  display: flex;
  gap: 1em;
  align-items: center;
}

.textNight {
  color: var(--color-night);
}

.viewPromoParticipatingStationContainer {
  display: flex;
  align-items: center;
  gap: 3em;
}

.contentAddPromoModal {
  @extend .textNight;
  width: 254px;
  height: 39px;
  margin: 37px 63px 29px 77px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
}

.contentLeavePageModal {
  @extend .textNight;
  width: 360px;
  height: 39px;
  margin: 0px 63px 29px 77px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
}

.popoverContainer {
  :global {
    .MuiPaper-root {
      // margin-left: 70px;
      margin-left: 50px;
      overflow: initial;
      background: transparent;
      width: 300px;
      position: relative;
      margin-top: -10px;
    }
  }
  max-height: 200px;
  width: 300px;
  position: relative;

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 0;
    background: #ffffff;
    top: 10px;
    margin-left: -5px;
    transform: rotate(45deg);
    border-radius: 2px;
  }
}
