.MuiAutocomplete-option:hover {
  background: var(--color-baby-blue);
  color: var(--color-purple);
  font-weight: 500;
}
.MuiListItem-root.MuiMenuItem-root {
  font-size: 14px;
}

.WAMuiChipInput-underline-28:after,
.WAMuiChipInput-underline-28:before,
.WAMuiChipInput-underline-28:hover:not(.WAMuiChipInput-disabled-27):not(
    .WAMuiChipInput-focused-26
  ):not(.WAMuiChipInput-error-29):before {
  border-bottom: 1px solid var(--color-ash);
}

.WAMuiChipInput-chipContainer-8 {
  min-height: 38px;
}
.MuiChip-root {
  height: 40px;
  margin-top: 5px;
  border-radius: 50px;
}

.MuiListItem-root.MuiMenuItem-root:hover {
  background: var(--color-baby-blue);
  color: var(--color-purple);
  font-weight: 500;
}

.MuiPopover-root {
  z-index: 11111111111111999999 !important;
}

.MuiPopover-paper {
  max-height: 270px;
}

.MuiClockPicker-root > div > div > div {
  background-color: #f5f6fa;
}

.MuiClockPicker-root > div > div > div:nth-child(3),
.MuiClockPicker-root > div > div > div:nth-child(2) {
  background-color: var(--color-purple);
  div {
    border-color: var(--color-purple);
  }
}

* {
  font-family: Roboto;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.text-right {
  text-align: right;
}

.gray {
  color: var(--color-cement);
}

.italic {
  font-style: italic;
}

.break-all {
  word-break: break-all;
}

.min-70 {
  min-width: 70px;
}

.min-80 {
  min-width: 80px;
}

.min-90 {
  min-width: 90px;
}

.min-100 {
  min-width: 100px;
}

.min-150 {
  min-width: 150px;
}

.max-60 {
  max-width: 60px;
}

.max-90 {
  max-width: 90px;
}

.link {
  color: var(--color-mid-blue);
  text-decoration: underline;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}

@keyframes fadeDown {
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes fadeUp {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.alerts {
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 344px;
  justify-content: center;
  z-index: 100000;

  > div {
    padding: 1.3em;
    border-radius: 10px;
    opacity: 1;
    -webkit-box-shadow: -2px 17px 22px 3px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: -2px 17px 22px 3px rgba(0, 0, 0, 0.05);
    box-shadow: -2px 17px 22px 3px rgba(0, 0, 0, 0.05);
  }

  > div.enter {
    animation-name: fadeDown;
    animation-duration: 0.5s;
  }

  > div.exit {
    animation-name: fadeUp;
    animation-duration: 0.5s;
  }
}
