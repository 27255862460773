.fieldContent {
  width: 290px;
  img {
    margin-left: -1em;
  }
}

.label {
  color: #212529 !important;
  width: 300px;
  font-weight: 500;
}

.shortContent {
  width: 150px;
}

.saveProductModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editSaveBtn {
  margin-top: -9em;
}



.contentLeavePageModal {
  color: var(--color-night);
  width: 360px;
  height: 39px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
}
