.table {
  table {
    width: 100%;
  }
}

.td {
  color: black;
}

.subtitle {
  color: darkgray;
  margin-top: 5px;
}

.textNight {
  color: black;
}

.imageContainer {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  border: 0.5px solid grey;
}
