.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldContent {
  width: 235px;
}

.fieldSelectAll {
  background-color: var(--color-light-grey);
  label {
    font-weight: bold;
    color: var(--color-cement);
  }
}

.search {
  width: 600px;
  margin-left: 10px;
}

.downloadButton {
  div {
    column-gap: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.popOver {
  max-height: 160px;
  z-index: 0;

  .reasonRemarksItem {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
  }
}

.alignToTop {
  vertical-align: top;
}

.primary {
  color: var(--color-white);
  background: #5349b7;
}

.textStyle {
  font-family: Roboto;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--color-night);
  z-index: 0 !important;
}

.circular {
  background: none;
  width: 20px !important;
  height: 20px !important;

  &.primary {
    color: var(--color-purple);
  }
}

.popoverContainer {
  :global {
    .MuiPaper-root {
      // margin-left: 70px;
      margin-left: -10px;
      overflow: initial;
      background-color: rgb(255, 255, 255) !important;
      width: 480px !important;
      height: fit-content;
      position: relative;
    }
  }
  width: 700px;

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 0;
    background: #ffffff;
    top: 10px;
    margin-left: -5px;
    transform: rotate(45deg);
    border-radius: 2px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 40%;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--color-cement);
    border-radius: 5px;
  }

  table td div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}
